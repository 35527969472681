/***  MEDIA FORMATTING WIDTH ***/

/* over 380px wide */
@media screen and (min-width: 380px) {
  .card-public,
  .chat-bubble.alt {
    padding: 4em 1em;
    margin: 0 0.5em;
  }

  .public-text-container {
    margin: 0 0.5em;
  }

  div.px380-center {
    text-align: center;
  }
  button.px380-center {
    margin-left: auto;
    margin-right: auto;
  }

  .crest-edit-container {
    padding: 10px;
  }

  .demo-request-button {
    padding: 0.8em;
  }

  .font-size-M {
    font-size: 1.6rem;
  }
  .font-size-L {
    font-size: 1.8rem;
  }
  .font-size-XL {
    font-size: 2rem;
  }
  .font-size-XXL {
    font-size: 2.25rem;
  }

  .login {
    padding: 3em 1.5em;
  }
  .login-heading {
    font-size: 2rem;
  }

  .modal-content {
    padding: 0.5rem;
  }
  .motto {
    font-size: 2rem;
  }

  .preview-img {
    max-width: 117px;
  }

  .public-nav {
    font-size: 0.9em;
  }

  .tag_width {
    max-width: 250px;
  }
}

/* over 500px wide */
@media screen and (min-width: 500px) {
  /* body,
  html {
    font-size: 15px;
  } */

  .auto-margin-narrow {
    margin: unset;
  }

  .card-block,
  .card-block-success {
    flex-direction: row;
  }
  .card-block-summary {
    display: flex;
    /* flex-direction: row; */
  }

  .demo-request-button {
    font-size: 1.1em;
    padding: 0.8em 1em;
  }

  div.img-view {
    font-size: 3em;
    padding: 0.25rem;
  }

  .block-info {
    padding: 0 1em;
    width: calc(100% - 140px);
  }

  .font-size-M {
    font-size: 1.8rem;
  }
  .font-size-L {
    font-size: 2rem;
  }
  .font-size-XL {
    font-size: 2.2rem;
  }
  .font-size-XXL {
    font-size: 2.5rem;
  }

  .login-heading {
    font-size: 2.2rem;
  }

  .modal-content {
    padding: 1rem;
    margin: 2rem auto;
    width: calc(100% - 2rem);
    max-width: 700px;
    border-radius: 6px;
    max-height: calc(100vh - 5rem);
  }
  .modal-dynamic {
    min-height: unset;
    height: unset;
  }

  .page-card {
    margin-left: -0.25rem;
    margin-right: -0.25rem;
    padding: 0.75rem;
  }

  .page-notification {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    padding: 1em;
  }

  .page-section {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  .preview-img {
    max-width: 149px;
  }

  .public-nav {
    font-size: 1em;
  }

  .card-post > .card-username {
    display: unset;
  }

  .tag_width {
    max-width: 300px;
  }

  .card-long {
    display: flex;
    flex-direction: row;
    overflow: hidden;
    padding: 1em;
  }

  .web-footer > .group {
    display: flex;
    flex-direction: row;
    gap: 6em;
  }
  .web-footer-paired {
    margin-top: 0;
  }
}

/* over 680px wide */
@media screen and (min-width: 680px) {
  .landing-screen > .card-heading,
  .card-heading {
    margin: 0 0.75em;
  }
  .card-public,
  .chat-bubble.alt {
    padding: 4em 2em;
    margin: 0 1em;
  }
  .card-public.tight {
    padding: 2em 1em !important;
  }
  .card-public.px680 {
    margin-top: unset;
  }

  .chat-bubble.alt {
    flex-basis: 300px;
    min-height: unset;
    margin-top: 0em !important;
  }
  .chat-bubble {
    padding: 2em;
    margin: 0;
  }

  .public-text-container {
    margin: 0 1em;
  }
  .public-header-container {
    flex-basis: 325px;
    margin-bottom: 0em;
  }

  .preview-img {
    max-width: 15rem;
  }

  .px680-hide {
    display: none;
  }
  .px680-show {
    display: initial;
  }

  div.img-view {
    font-size: 4em;
    padding: 0.5rem;
  }

  .flex-680px {
    display: flex;
  }

  .flex-center-680px {
    align-items: center;
  }

  .flex-switch-680px {
    display: flex;
    flex-direction: row;
  }

  .flex-switch-900px.gapXL,
  .flex-switch-680px.gapXL {
    gap: 2em;
  }
  .flex-switch-680px.reverse {
    flex-direction: row-reverse;
  }

  .font-size-XL {
    font-size: 2.4rem;
  }
  .font-size-XXL {
    font-size: 2.6rem;
  }

  .hidden-680px {
    display: none;
  }

  .landing-video {
    flex-basis: 640px;
    flex-shrink: 0.67;
    padding: 0;
    max-width: unset;
  }

  .motto {
    gap: 0.6em;
    flex-grow: 1;
    flex-basis: 400px;
    padding: 0;
    margin-bottom: 0;
    text-align: unset;
  }

  .phone-recording {
    width: 320px;
  }

  .pill-button:hover > .glyphs,
  .public-nav-signin:hover > .glyphs {
    transition: all 0.2s ease;
    transform: translate(4px, 0px);
  }

  .tab-set-group,
  .tab-set-group-buttons {
    display: flex;
    flex-direction: row;
    gap: 1.5em;
  }
  .tab-set-group > div,
  .tab-set-group-buttons > button {
    flex: 1;
  }
}

/* over 900px wide */
@media screen and (min-width: 900px) {
  .flex-900px {
    display: flex;
  }

  .card-public.tight {
    padding: 2em !important;
  }

  .chat-bubble.alt {
    flex-basis: 450px;
  }

  .flex-switch-900px {
    display: flex;
    flex-direction: row;
  }
  .flex-switch-900px.even {
    flex-basis: 50%;
  }
  .flex-switch-900px.reverse {
    flex-direction: row-reverse;
  }

  .font-size-R {
    font-size: 1.4rem;
  }
  .font-size-XL {
    font-size: 2.6rem;
  }
  .font-size-XXL {
    font-size: 3rem;
  }

  .landing-video {
    flex-shrink: 0.3;
  }

  .live-stage {
    display: flex;
    position: relative;
  }
  .live-panel {
    margin-top: 0;
    position: unset;
    height: unset;
    flex-basis: 30%;
    flex-grow: 0;
    flex-shrink: 0;
  }
  .live-panel-box {
    width: 30%;
  }
  .live-control.message {
    width: 30%;
  }
  .live-video {
    flex-basis: 70%;
    flex-grow: 0;
    flex-shrink: 0;
  }

  .mobile-center {
    text-align: unset;
  }
  .mobile-justify {
    justify-content: flex-start;
  }
  .mobile-margin {
    margin: 0px;
  }

  .modal-content {
    padding: 1rem 2rem;
    margin: 3rem auto;
    width: 700px;
  }

  .motto {
    font-size: 2.4rem;
  }

  div.natural-edit {
    margin: 0.25rem 0;
  }

  .page-card {
    margin-left: -0.5rem;
    margin-right: -0.5rem;
  }

  .page-notification {
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .page-section {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .public.section {
    padding: 1em 0.5em;
  }
  .public-getyongo {
    display: flex;
    gap: 1em;
  }
  .public-getyongo img {
    width: 300px;
    height: 300px;
  }
  .public-getyongo > div {
    text-align: center;
  }

  .px900-hide {
    display: none;
  }
  .px900-show {
    display: initial;
  }

  img.public-benefits {
    max-width: 400px;
    width: 400px;
    height: 400px;
    margin: -30px auto 0 auto;
    display: block;
  }

  .sidebar-container {
    flex-basis: 220px;
  }

  .sidebar {
    width: 220px;
  }
  .sidebar-background {
    width: 220px;
  }

  .sidebar-overlay.visible {
    display: none !important;
  }

  .thumb-group-container {
    margin: 0 -8px;
  }

  .topbar {
    display: none;
  }

  .web-footer {
    display: flex;
    gap: 4em;
    align-items: flex-start;
  }
  .web-footer > .group {
    margin-top: 0.8em;
  }

  .web-search {
    margin: 0;
  }
}

/* over 1200px wide */
@media screen and (min-width: 1200px) {
  .font-size-R {
    font-size: 1.6rem;
  }

  .tab-set,
  .tab-set-buttons {
    display: flex;
    gap: 1.5em;
  }
  .tab-set-group,
  .tab-set-buttons > button {
    flex: 1;
  }
  .tab-set-group-buttons {
    display: none;
  }
}

/***  MEDIA FORMATTING HEIGHT ***/
/* under 720px high */
@media screen and (max-height: 720px) {
  .login {
    padding-top: 0.5em;
  }
}
